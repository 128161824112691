<template>
  <div>
    <div class="mb-2">
      <div class="row">
        <div class="col-2">
          <b-button variant="outline-primary"
                    block
                    @click="showImageLibrary = true"
          >
            <feather-icon icon="PlusIcon" />
            {{ $t('image-library-module.add-more') }}
          </b-button>
        </div>
        <div class="col-2">
          <sync-progress :is-synching="isSynching"
                         @onSync="handleSyncWordNet"
          />
        </div>
        <div class="col-2">
          <sync-progress :is-synching="isSynchingTrans"
                         title="Sync Other Version"
                         queue-key="imagLibTrans"
                         @onSync="handleImageLibraryOtherVersion"
          />
        </div>
        <div class="col-2">
          <b-button variant="outline-primary"
                    class="btn-block"
                    @click="handleSelectAll"
          >
            {{ 'Select All' }}
          </b-button>
        </div>
        <div v-if="selectedImageLibraryForImage.length"
             class="col-3"
        >
          <b-button :disabled="isBulkImageSynching"
                    variant="outline-primary"
                    @click="handleBulkImageUpdate"
          >
            <b-spinner v-if="isBulkImageSynching"
                       small
            />
            Regenerate Selected Images
          </b-button>
        </div>
      </div>

      <div class="float-right mt-1">
        <router-link
          v-b-tooltip.hover="$t('image-library-module.assets-management')"
          class="bg-primary text-white p-1 rounded mr-1 btn"
          :to="`/${self.usertype}/assets-management`"
        >
          <feather-icon icon="FileIcon"
                        size="18"
          />
          {{ $t('image-library-module.assets-management') }}
        </router-link>
        <b-button
          v-b-tooltip.hover="$t('image-library-module.upload-csv-file')"
          class="bg-success text-white p-1 mr-1 rounded"
          @click="showBulkUploadModal = true"
        >
          <feather-icon icon="UploadIcon"
                        size="18"
          />
          {{ $t('image-library-module.batch-upload') }}
        </b-button>

        <b-button
          v-b-tooltip.hover="`On Progress`"
          class="bg-warning text-white p-1 rounded btn-warning"
          @click="showOnProgressModal = true"
        >
          <feather-icon icon="EyeIcon"
                        size="18"
          />
          {{ $t('on-progress') }}
        </b-button>
      </div>

    </div>
    <manage-image-library :show="showImageLibrary"
                          :imagelibrary-info="imageLibraryToEdit"
                          @close="handleClose"
                          @reFetch="getImageLibrary()"
    />
    <div class="row mb-2">
      <div class="col-4">
        <b-input-group>
          <b-form-input v-model="searchTerm"
                        :placeholder="$t('image-library-module.search-image')"
                        debounce="800"
                        @update="getImageLibrary()"
          />
          <BInputGroupAppend v-if="searchTerm"
                             is-text
          >
            <feather-icon icon="SaveIcon"
                          role="button"
                          @click="wordToGenerateImage = searchTerm"
            />
          </BInputGroupAppend>
        </b-input-group>
      </div>
      <div class="col-4">
        <b-form-select v-model="selectedLang"
                       :options="languageOptions"
                       text-field="name"
                       value-field="value"
                       @change="getImageLibrary()"
        />
      </div>
    </div>
    <b-overlay :show="isProcessing">
      <b-row>
        <b-col v-for="image,index of imageLibrary"
               :key="index"
               md="3"
        >
          <library-card :item="image"
                        :is-selected="!!selectedImageLibraryForImage.find(i => i == image.id)"
                        @onEdit="handleEdit"
                        @onDelete="value => imageLibraryToDelete = value"
                        @onTranslate="handleLanguageTranslate"
                        @handleSelect="v => handleSelect(v, image.id)"
          />
        </b-col>
      </b-row>

    </b-overlay>
    <Pagination :pagination="paginationData"
                :jump-to="true"
                :is-processing="isProcessing"
                @onPaginationChange="page => getImageLibrary(searchTerm, page) "
    />
    <DeleteModal title="Delete?"
                 :show="!!imageLibraryToDelete"
                 sub-title="Are you sure you want to delete?"
                 :on-delete="handleDelete"
                 :is-processing-delete="isDeleting"
                 @close="imageLibraryToDelete = null"
    />
    <DeleteModal title="Generate?"
                 :show="!!wordToGenerateImage"
                 sub-title="Are you sure you want generate word?"
                 :on-delete="handleGenerate"
                 :is-processing-delete="isProcessing"
                 varient="primary"
                 @close="wordToGenerateImage = null"
    />
    <LanguageTranslate v-if="libraryIdToTranslate"
                       :img-id="libraryIdToTranslate"
                       @close="libraryIdToTranslate = null"
    />
    <BulkUploadModal
      :show="showBulkUploadModal"
      @close="handleClose"
    />
    <OnProgressModal
      v-if="showOnProgressModal"
      @close="handleClose"
      @onEdit="handleEdit"
      @onDelete="value => imageLibraryToDelete = value"
      @onTranslate="handleLanguageTranslate"
    />
  </div>
</template>
<script>
import {
  BButton, BRow, BCol, BOverlay, BFormInput, BFormSelect, BSpinner, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import Pagination from '@/views/common/components/Pagination.vue'
import locales from '@/const/locales'
import ManageImageLibrary from './components/Manage.vue'
import LibraryCard from './components/LibraryCard.vue'
import BulkUploadModal from './components/BulkUploadModal.vue'
import SyncProgress from './components/SyncProgress.vue'
import LanguageTranslate from './components/LanguageTranslate.vue'
import OnProgressModal from './components/OnProgressModal.vue'

export default {
  components: {
    BulkUploadModal,
    BButton,
    BSpinner,
    ManageImageLibrary,
    LibraryCard,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    BFormInput,
    Pagination,
    DeleteModal,
    SyncProgress,
    LanguageTranslate,
    OnProgressModal,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      imageLibrary: [],
      selectedImageLibraryForImage: [],
      showImageLibrary: false,
      imageLibraryToEdit: null,
      imageLibraryToDelete: null,
      wordToGenerateImage: '',
      isDeleting: false,
      isProcessing: false,
      searchTerm: '',
      paginationData: {},
      showBulkUploadModal: false,
      showOnProgressModal: false,
      self: getUserData(),
      isSynching: false,
      isSynchingTrans: false,
      libraryIdToTranslate: null,
      selectedLang: 'english',
      isBulkImageSynching: false,
      languageOptions: locales.map(i => ({
        name: i.name,
        value: i.value,
      })),
    }
  },
  mounted() {
    this.getImageLibrary()
  },
  methods: {
    handleSelectAll() {
      this.selectedImageLibraryForImage = this.imageLibrary.map(i => i.id)
    },
    handleLanguageTranslate(id) {
      this.libraryIdToTranslate = id
    },
    getImageLibrary(term = this.searchTerm, page = (this.paginationData.current_page || 1)) {
      this.isProcessing = true
      this.isAllSelected = false
      useJwt.getImageLibrary({
        params: {
          term,
          page,
          lang: this.selectedLang,
        },
      }).then(response => {
        this.imageLibrary = response.data?.data?.data
        this.paginationData = {
          current_page: response.data.data.current_page,
          per_page: response.data.data.per_page,
          total: response.data.data.total,
          last_page: response.data.data.last_page,
        }
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleClose() {
      this.showImageLibrary = false
      this.imageLibraryToEdit = null
      this.showBulkUploadModal = false
      this.showOnProgressModal = false
    },
    handleGenerate() {
      const payload = [
        {
          word: this.wordToGenerateImage,
          id: null,
        },
      ]
      this.isProcessing = true
      useJwt.saveImageLibraryWord({
        words: payload,
      }).then(response => {
        this.wordToGenerateImage = ''
        this.showSuccessMessage(response)
        this.getImageLibrary()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    handleDelete() {
      this.isDeleting = true
      useJwt.deleteImageLibrary(this.imageLibraryToDelete.id).then(response => {
        this.imageLibraryToDelete = null
        this.showSuccessMessage(response)
        this.getImageLibrary()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isDeleting = false
      })
    },
    handleEdit(library) {
      this.imageLibraryToEdit = { ...library }
      this.showImageLibrary = true
    },
    handleImageLibraryOtherVersion() {
      this.isSynchingTrans = true
      useJwt.synchImgLibraryOtherVersion().then(() => {
      }).catch(error => {
        this.isSynchingTrans = false
        this.showErrorMessage(error)
      })
    },
    handleSyncWordNet() {
      this.isSynching = true
      useJwt.synchImgLibrary().then(() => {
      }).catch(error => {
        this.isSynching = false
        this.showErrorMessage(error)
      })
    },
    handleBulkImageUpdate(forceUpdate = null) {
      this.isBulkImageSynching = true
      useJwt.regenerateImgLibrary({
        img_ids: this.selectedImageLibraryForImage,
        force_update: forceUpdate,
      }).then(response => {
        if (response.data.data.limit_crossed) {
          this.$swal.fire({
            title: response.data.data.message,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            allowOutsideClick: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.handleBulkImageUpdate(true)
            }
          })
          return
        }
        this.selectedImageLibraryForImage = []
        this.showSuccessMessage(response)
        this.getImageLibrary()
      }).finally(() => {
        this.isBulkImageSynching = false
      })
    },
    handleSelect(v, imgId) {
      if (v) {
        this.selectedImageLibraryForImage.push(imgId)
      } else {
        this.selectedImageLibraryForImage = this.selectedImageLibraryForImage.filter(i => i !== imgId)
      }
    },
  },
}
</script>
